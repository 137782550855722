import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          transform="translate(25.000000, 26.000000)"
          d="M 20.201 22.101 L 26.651 22.101 A 21.789 21.789 0 0 1 26.293 26.179 Q 25.851 28.497 24.861 30.309 A 11.032 11.032 0 0 1 23.251 32.551 A 11.067 11.067 0 0 1 17.582 35.769 A 16.865 16.865 0 0 1 13.651 36.201 A 16.472 16.472 0 0 1 7.84 35.259 Q 0.62 32.546 0.05 22.016 A 34.488 34.488 0 0 1 0.001 20.151 L 0.001 17.151 Q 0.001 5.908 5.529 2.13 A 9.886 9.886 0 0 1 7.001 1.301 Q 9.536 0.102 13.048 0.008 A 22.606 22.606 0 0 1 13.651 0.001 A 16.957 16.957 0 0 1 17.775 0.476 A 12.196 12.196 0 0 1 22.926 3.126 A 10.098 10.098 0 0 1 26.116 8.577 A 15.604 15.604 0 0 1 26.501 12.151 L 20.001 12.151 A 13.261 13.261 0 0 0 19.548 10.371 Q 18.991 8.708 18.051 7.701 Q 16.651 6.201 13.651 6.201 A 8.827 8.827 0 0 0 11.639 6.415 Q 9.62 6.888 8.451 8.401 Q 7.251 9.951 7.051 13.701 Q 6.96 14.785 6.952 16.562 A 79.081 79.081 0 0 0 6.951 16.951 L 6.951 20.051 A 32.535 32.535 0 0 0 7.045 22.622 Q 7.243 25.109 7.859 26.643 A 5.369 5.369 0 0 0 8.551 27.901 A 5.223 5.223 0 0 0 11.326 29.705 Q 12.373 30.001 13.676 30.001 A 9.796 9.796 0 0 0 15.37 29.865 Q 16.27 29.706 16.963 29.363 A 3.891 3.891 0 0 0 18.476 28.076 A 7.345 7.345 0 0 0 19.262 26.542 Q 19.84 25.036 20.118 22.838 A 27.339 27.339 0 0 0 20.201 22.101 Z"
          fill="#64FFDA"
        />
      </g>
      <path
        stroke="#64FFDA"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
